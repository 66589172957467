import React from "react";
// Styles
import "./skillCardsAll.scss";
// Components
import SkillCard from "./SkillCard";
// Images
// - HTML
import htmlLogo from "../../assets/images/skills/htmlLogo.png";
import reactLogo from "../../assets/images/skills/reactLogo.png";
// - CSS
import css3Logo from "../../assets/images/skills/css3Logo.png";
import sassLogo from "../../assets/images/skills/sassLogo.png";
import lessLogo from "../../assets/images/skills/lessLogo.png";
import bootstrapLogo from "../../assets/images/skills/bootstrapLogo.png";
import materializeLogo from "../../assets/images/skills/materializeLogo.png";
// - JavaScript
import javaScriptLogo from "../../assets/images/skills/javascriptLogo.png";
import jQueryLogo from "../../assets/images/skills/jqueryLogo.png";
import nodeLogo from "../../assets/images/skills/nodeJsLogo.png";
import angularLogo from "../../assets/images/skills/angularLogo.png";
import vueLogo from "../../assets/images/skills/vueLogo.png";
// - Backend
import postgresLogo from "../../assets/images/skills/postgresqlLogo.png";
import mongoLogo from "../../assets/images/skills/mongodbLogo.png";
// - Build Tools
import webpackLogo from "../../assets/images/skills/webpackLogo.png";
import gulpLogo from "../../assets/images/skills/gulpLogo.png";
import gruntLogo from "../../assets/images/skills/gruntLogo.png";
import parcelLogo from "../../assets/images/skills/parcelLogo.png";
import javaLogo from "../../assets/images/skills/javaLogo.png";
import pythonLogo from "../../assets/images/skills/pythonLogo.png";
// - Other
import githubLogo from "../../assets/images/skills/githubLogo.png";
import herokuLogo from "../../assets/images/skills/herokuLogo.png";
import photoshopLogo from "../../assets/images/skills/photoshopLogo.png";
import illustratorLogo from "../../assets/images/skills/illustratorLogo.png";
import adobeXDLogo from "../../assets/images/skills/adobeXDLogo.svg";
// - Mobile
import flutterLogo from "../../assets/images/skills/flutterLogo.svg";

function CardContainer() {
  return (
    <div className="cardContainer">
      <SkillCard
        skillArea={"HTML"}
        content={[
          {
            image: htmlLogo,
            techName: "HTML5"
          },
          {
            image: reactLogo,
            techName: "JSX"
          }
        ]}
      />
      <SkillCard
        skillArea={"CSS"}
        content={[
          {
            image: css3Logo,
            techName: "CSS3"
          },
          {
            image: sassLogo,
            techName: "SASS"
          },
          {
            image: lessLogo,
            techName: "LESS"
          },
          {
            image: bootstrapLogo,
            techName: "Bootstrap"
          },
          {
            image: materializeLogo,
            techName: "Materialize"
          }
        ]}
      />
      <SkillCard
        skillArea={"Javascript"}
        content={[
          {
            image: reactLogo,
            techName: "React"
          },
          {
            image: angularLogo,
            techName: "Angular"
          },
          {
            image: vueLogo,
            techName: "Vue"
          },
          {
            image: javaScriptLogo,
            techName: "Javascript"
          },
          {
            image: jQueryLogo,
            techName: "jQuery"
          },
          {
            image: nodeLogo,
            techName: "Node"
          }
        ]}
      />
      <SkillCard
        skillArea={"Back-End"}
        content={[
          {
            image: mongoLogo,
            techName: "MongoDB"
          },
          {
            image: postgresLogo,
            techName: "PostgreSQL"
          },
          {
            image: javaLogo,
            techName: "Java"
          },
          {
            image: pythonLogo,
            techName: "Python"
          }
        ]}
      />
      <SkillCard
        skillArea={"Mobile"}
        content={[
          {
            image: flutterLogo,
            techName: "Flutter"
          }
        ]}
      />
      <SkillCard
        skillArea={"Build Tools"}
        content={[
          {
            image: webpackLogo,
            techName: "Webpack"
          },
          {
            image: gulpLogo,
            techName: "Gulp"
          },
          {
            image: gruntLogo,
            techName: "Grunt"
          },
          {
            image: parcelLogo,
            techName: "Parcel"
          }
        ]}
      />
      <SkillCard
        skillArea={"Other"}
        content={[
          {
            image: githubLogo,
            techName: "GitHub"
          },
          {
            image: herokuLogo,
            techName: "Heroku"
          },
          {
            image: photoshopLogo,
            techName: "Photoshop"
          },
          {
            image: illustratorLogo,
            techName: "Illustrator"
          },
          {
            image: adobeXDLogo,
            techName: "Adobe XD"
          }
        ]}
      />
    </div>
  );
}

export default CardContainer;

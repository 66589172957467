import React from "react";
import "./work.scss";
import ProjectCard from "../../components/projectCard/ProjectCard";
import projectData from "../../assets/data/projectData";

function Work() {
  // TODO matt: we need to add the images for the examples to project data
  return (
    <div id="work">
      <div id="work__notice">
        <p>Sites may be hosted on Heroku and can take a moment to start</p>
      </div>
      <div id="work__cards-container">
        {projectData &&
          projectData.map((project, key) => (
            <ProjectCard key={key} card={project} />
          ))}
      </div>
    </div>
  );
}

export default Work;

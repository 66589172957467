import React from "react";
// Styles
import "./home.scss";
import Typist from "react-typist";

function home({ history }) {
  const animationText = [
    "S e l f - M o t i v a t e d ",
    "P a s s i o n a t e ",
    "A n a l y t i c a l ",
    "T e a m - P l a y e r ",
    "F l e x i b l e ",
    "F a s t - L e a r n e r ",
    "A d a p t a b i l i t y ",
    "P a t i e n t ",
    "L i s t e n e r ",
    "D e t a i l - O r i e n t e d ",
    "P r o b l e m - S o l v e r ",
    "O p e n - M i n d e d ",
    "C o m m u n i c a t o r ",
    "P o s i t i v e ",
    "C r e a t i v e ",
    "E n t h u s i a s t i c ",
    "W o r k - E t h i c ",
    "C o l l a b o r a t i v e ",
    "E m p a t h i c ",
    "A p p r o a c h a b l e ",
    "H e l p f u l ",
    "A c c o u n t a b i l i t y ",
    "C r e a t i v i t y ",
    "T i m e - M a n a g e m e n t ",
    "E x p e r i e n c e ",
    "D e d i c a t e d ",
    "W i l l i n g n e s s - T o - G r o w ",
    "P e r s e v e r a n c e ",
    "C o n s i s t e n c y ",
    "F o c u s e d ",
    "T r o u b l e s h o o t e r ",
    "V i s u a l - T h i n k e r ",
    "E f f i c i e n t ",
    "O r g a n i z e d ",
    "O p t i m i s t i c ",
    "M u l t i t a s k i n g ",
    "D e c i s i o n - M a k e r ",
    "F r i e n d l y ",
    "L o g i c a l ",
    "P e r s i s t e n t ",
    "R e s p e c t f u l ",
    "I n s i g h t ",
    "R e s p o n s i b l e ",
    "G o a l - S e t t e r ",
    "L e a d e r ",
    "H e l p f u l ",
    "M o t i v a t e d ",
    "D r i v e n ",
    "H a r d - W o r k i n g ",
    "T a l e n t e d",
    "F l e x i b l e ",
    "W o r k - E t h i c ",
    "C o l l a b o r a t i v e ",
    "E m p a t h i c ",
    "A p p r o a c h a b l e ",
    "H e l p f u l ",
    "A c c o u n t a b i l i t y ",
    "C r e a t i v i t y ",
    "T i m e - M a n a g e m e n t ",
    "E x p e r i e n c e ",
    "D e d i c a t e d ",
    "P o s i t i v e ",
    "C r e a t i v e ",
    "E n t h u s i a s t i c ",
    "W o r k - E t h i c ",
    "S e l f - M o t i v a t e d ",
    "P a s s i o n a t e ",
    "A n a l y t i c a l ",
    "T e a m - P l a y e r ",
    "F l e x i b l e ",
    "F a s t - L e a r n e r ",
    "A d a p t a b i l i t y ",
    "P a t i e n t ",
    "L i s t e n e r ",
    "D e t a i l - O r i e n t e d ",
    "P r o b l e m - S o l v e r ",
    "O p e n - M i n d e d ",
    "C o m m u n i c a t o r ",
    "P o s i t i v e ",
    "C r e a t i v e ",
    "E n t h u s i a s t i c ",
    "W o r k - E t h i c ",
    "C o l l a b o r a t i v e ",
    "O p e n - M i n d e d ",
    "C o m m u n i c a t o r ",
    "P o s i t i v e ",
    "C r e a t i v e ",
    "E n t h u s i a s t i c ",
    "W o r k - E t h i c ",
    "C o l l a b o r a t i v e ",
    "E m p a t h i c ",
    "A p p r o a c h a b l e ",
    "H e l p f u l ",
    "A c c o u n t a b i l i t y ",
    "C r e a t i v i t y ",
    "T i m e - M a n a g e m e n t ",
    "E x p e r i e n c e ",
    "D e d i c a t e d ",
    "W i l l i n g n e s s - T o - G r o w ",
    "P e r s e v e r a n c e ",
    "C o n s i s t e n c y ",
    "F o c u s e d ",
    "T r o u b l e s h o o t e r ",
    "V i s u a l - T h i n k e r ",
    "E f f i c i e n t ",
    "O r g a n i z e d ",
    "O p t i m i s t i c ",
    "M u l t i t a s k i n g ",
    "D e c i s i o n - M a k e r ",
    "F r i e n d l y ",
    "L o g i c a l ",
    "P e r s i s t e n t ",
    "R e s p e c t f u l ",
  ];

  return (
    <div id="home">
      <div id="home-wrapper">
        <div id="home__background-container">
          <h1>
            {animationText.map((word, key) => (
              <span key={key}>{word}</span>
            ))}
          </h1>
        </div>
        <div id="home__content">
          <div id="home__title">
            <h1>
              <Typist
                className="Cursor"
                cursor={{
                  show: true,
                  blink: true,
                  element: "|",
                  hideWhenDone: true,
                  hideWhenDoneDelay: 500,
                }}
              >
                <Typist.Delay ms={900} />
                Hi!
                <Typist.Backspace count={3} delay={200} />
                Hello?
                <Typist.Backspace count={6} delay={200} />
                How am I supposed to start this?
                <Typist.Backspace count={32} delay={200} />
                Well here we go...
                <Typist.Backspace count={18} delay={200} />
                Hello Everyone.
                <br />
                My name is Matthew.
                <br />
                I am a Software Engineer.
                <br />
              </Typist>
            </h1>
          </div>
          <div id="content__sub">
            <h3>MERN / MEAN / MEVN</h3>
            <div id="sub__btn">
              <p onClick={() => history.push("/contact")}>CONTACT ME</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default home;

import React from "react";
import { NavLink } from "react-router-dom";
import { withRouter } from "react-router-dom";
// Styles
import "./navbar.scss";

function Navbar(props) {
  const handleLogoClick = () => props.history.push("/");

  const handleHamburgerClick = () => {
    document.getElementById("nav__middle").classList.toggle("menu-active");
  };

  return (
    <nav id="nav">
      {/* // - Top */}
      <div onClick={handleLogoClick} id="nav__logo-wrapper">
        <div id="nav__logo-container">
          <h4>
            <span>M</span>at
          </h4>
          <h4>t</h4>
        </div>
      </div>

      {/* // - Middle */}
      <ul onClick={handleHamburgerClick} id="nav__middle">
        <NavLink id="middle__link" exact to="/">
          <i className="fas fa-home" />
          <p>Home</p>
        </NavLink>

        <NavLink id="middle__link" to="/about">
          <i className="fas fa-user-astronaut" />
          <p>About</p>
        </NavLink>

        <NavLink id="middle__link" to="/skills">
          <i className="fas fa-code" />
          <p>Skills</p>
        </NavLink>

        {/* <NavLink id="middle__link" to="/work">
          <i className="fas fa-laptop-code"></i>
          <p>My Work</p>
        </NavLink> */}

        <NavLink id="middle__link" to="/contact">
          <i className="far fa-envelope" />
          <p>Contact</p>
        </NavLink>
      </ul>

      {/* // - Lower */}
      <ul id="nav__bottom">
        <a
          alt="Matthew Endicott's github page"
          target="_blank"
          rel="noopener noreferrer"
          href="https://github.com/PCDSandwichMan"
        >
          <i className="fab fa-github"></i>
        </a>
        <a
          alt="Matthew Endicott's linkedin page"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.linkedin.com/in/matthewendicott"
        >
          <i className="fab fa-linkedin-in"></i>
        </a>
        <a
          alt="Matthew Endicott's resume"
          target="_blank"
          rel="noopener noreferrer"
          href="https://docs.google.com/document/d/17Nn4XXZvmJWQ0A6Bw0JPXCuag90KF5qdJ29HziD2uxs/edit?usp=sharing"
        >
          <i className="far fa-file"></i>
        </a>
        <a
          alt="Matthew Endicott's email"
          target="_blank"
          rel="noopener noreferrer"
          href="mailto:matthew.endicott.dev@gmail.com"
        >
          <i className="far fa-paper-plane"></i>
        </a>
      </ul>
      <i
        onClick={handleHamburgerClick}
        id="nav__hamburgerMenu"
        className="fas fa-bars"
      />
    </nav>
  );
}

export default withRouter(Navbar);

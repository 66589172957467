import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
// Styles
import "./styles/App.scss";
// Components
import Navbar from "./components/navbar/Navbar";
// Pages
import Home from "./pages/home/Home";
import About from "./pages/about/About";
import Skills from "./pages/skills/Skills";
import Work from "./pages/work/Work";
import Contact from "./pages/contact/Contact";

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar /> 
          <div id="app__overflow">
            <Route
              render={({ location }) => (
                <TransitionGroup className="test">
                  <CSSTransition
                    key={location.key}
                    timeout={450}
                    classNames="fade"
                  >
                    <Switch>
                      <Route exact path="/" component={Home} />
                      <Route path="/about" component={About} />
                      <Route path="/skills" component={Skills} />
                      <Route path="/work" component={Work} />
                      <Route path="/contact" component={Contact} />
                    </Switch>
                  </CSSTransition>
                </TransitionGroup>
              )}
            /> 
          </div>
      </Router>
    </div>
  );
}

export default App;

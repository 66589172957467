import React from "react";
// Styles
import "./contact.scss";

function Contact() {
  return (
    <div id="contactPage">
      <div id="contactPage__content">
        <h1>Contact Me</h1>
        <h4>
          If you have any questions, please don’t hesitate to contact using form
          below…
        </h4>
        <form
          id="contactForm"
          action="https://formspree.io/f/xleoppab"
          method="POST"
        >
          <div id="contactForm__topRow">
            <input type="text" name="name" placeholder="Name" />
            <input type="text" name="email" placeholder="Email" />
          </div>
          <input
            id="contactForm__subject"
            type="text"
            name="subject"
            placeholder="Subject"
          />
          <textarea name="message" placeholder="Message" />
        </form>
        <button className="brk-btn" form="contactForm">
          Send
        </button>
      </div>
    </div>
  );
}

export default Contact;

import gitCardImage from "../images/projects/gitSearchCard.png";
import torikkoCarImage from "../images/projects/torikkoCard.png";
import kickstarterSuccessImage from "../images/projects/kickstarterSuccessCard.png";
import inspireCardImage from "../images/projects/inspireCard.png";
import shipCrewImage from "../images/projects/shipCrewCard.png";
import swobCardImage from "../images/projects/swobCard.png";
// - Image

// - HTML
import htmlLogo from "../../assets/images/skills/htmlLogo.png";
import reactLogo from "../../assets/images/skills/reactLogo.png";
// - CSS
import sassLogo from "../../assets/images/skills/sassLogo.png";
// - JavaScript
import javaScriptLogo from "../../assets/images/skills/javascriptLogo.png";
import nodeLogo from "../../assets/images/skills/nodeJsLogo.png";
// - Backend
import postgresLogo from "../../assets/images/skills/postgresqlLogo.png";
import mongoLogo from "../../assets/images/skills/mongodbLogo.png";
// - Build Tools
import webpackLogo from "../../assets/images/skills/webpackLogo.png";
import parcelLogo from "../../assets/images/skills/parcelLogo.png";
// - Other
import photoshopLogo from "../../assets/images/skills/photoshopLogo.png";
import adobeXDLogo from "../../assets/images/skills/adobeXDLogo.svg";

export default [
  {
    pageUrl: "https://torikko-art-club.herokuapp.com",
    gitURL: "none",
    title: "Torikko Art Club",
    info:
      "Torikko Art Club is an e-commerce platform focused around user to user trades. Features of this site include a live chat interface, online payments, mailing system, authentication, and permissioning",
    image: torikkoCarImage,
    toolLogos: [reactLogo, sassLogo, nodeLogo, mongoLogo, adobeXDLogo]
  },
  {
    pageUrl: "https://starwars-online-battles.herokuapp.com",
    gitURL: "https://github.com/PCDSandwichMan/SWOB",
    title: "SWOB",
    info:
      "Star Wars Online Battle features online user collaboration, multiple API integrations, heavy server side game mechanics, emailing integration for password resets, and user authentication.",
    image: swobCardImage,
    toolLogos: [
      reactLogo,
      sassLogo,
      nodeLogo,
      mongoLogo,
      webpackLogo,
      photoshopLogo
    ]
  },
  {
    pageUrl: "https://kickstarter-success.netlify.com/",
    gitURL: "https://github.com/PCDSandwichMan/firstBuildWeekLambda/",
    title: "Kickstarter Success",
    info:
      "I developed the landing page for our team of 8 developers for our build week at Lambda School. This uses Jump.js, Sal.js, and Parcel Bundler.",
    image: kickstarterSuccessImage,
    toolLogos: [htmlLogo, javaScriptLogo, sassLogo, adobeXDLogo, webpackLogo]
  },
  {
    pageUrl: "https://git-book.netlify.com/",
    gitURL: "https://github.com/build-week-github-user-breakdown/Front-end/",
    title: "GitBook",
    info:
      "GitBook is an account management tool that allows you too analyze commit rates, common tendencies, and personal information. This is integrated with  GitHub's OAuth2.",
    image: gitCardImage,
    toolLogos: [
      reactLogo,
      sassLogo,
      nodeLogo,
      postgresLogo,
      webpackLogo,
      adobeXDLogo
    ]
  },
  {
    pageUrl: "https://ship-crew-mern.herokuapp.com/",
    gitURL: "https://github.com/PCDSandwichMan/ship-crew",
    title: "Ship Crew",
    info:
      "This is a full-stack MVC structure MERN app with user authentication and CRUD functionality",
    image: shipCrewImage,
    toolLogos: [
      reactLogo,
      sassLogo,
      nodeLogo,
      mongoLogo,
      webpackLogo,
      photoshopLogo
    ]
  },
  {
    pageUrl: "https://inspire-react-app.herokuapp.com",
    gitURL: "https://github.com/PCDSandwichMan/Inspire",
    title: "Inspire",
    info:
      "This is a React-App that utilizes Redux and React-Router. This mobile-friendly just as the majorly of my site so give it a shot.",
    image: inspireCardImage,
    toolLogos: [htmlLogo, sassLogo, javaScriptLogo, adobeXDLogo, parcelLogo]
  }
];

import React from "react";
// Styles
import "./about.scss";
// Animations
// Logos
import vueLogo from "../../assets/images/skills/vueLogo.png";
import reactLogo from "../../assets/images/skills/reactLogo.png";
import angularLogo from "../../assets/images/skills/angularLogo.png";
import htmlLogo from "../../assets/images/skills/htmlLogo.png";
import css3Logo from "../../assets/images/skills/css3Logo.png";
import javaScriptLogo from "../../assets/images/skills/javascriptLogo.png";
import nodeLogo from "../../assets/images/skills/nodeJsLogo.png";
import javaLogo from "../../assets/images/skills/javaLogo.png";
import pythonLogo from "../../assets/images/skills/pythonLogo.png";
import lambdaLogo from "../../assets/images/about/lambdaLogo.svg";
import confetti from "canvas-confetti";

function About() {
  let end;
  let colors;
  const frame = (colorOne, colorTwo) => {
    confetti({
      particleCount: 2,
      angle: 60,
      spread: 55,
      origin: {
        x: 0,
      },
      colors: colors,
    });
    confetti({
      particleCount: 2,
      angle: 120,
      spread: 55,
      origin: {
        x: 1,
      },
      colors: colors,
    });

    if (Date.now() < end) {
      requestAnimationFrame(frame);
    }
  };

  const handleClick = (colorOne, colorTwo) => {
    const warningMessage = document.getElementById("about__warning");
    if (warningMessage && end && Date.now() < end) {
      warningMessage.classList.add("active-warning");
      setTimeout(() => {
        warningMessage.classList.remove("active-warning");
      }, 6000);
    }
    end = Date.now() + 110 * 10;
    colors = [colorOne, colorTwo];
    frame();
  };
  return (
    <div id="about">
      <p id="about__warning">
        Unless your computer is The Summit supercomputer you may want to only
        press one at a time...
      </p>
      <main>
        <div id="about__text-container">
          <h1>Who Am I</h1>
          <div id="about__article-wrapper">
            <p> 
              I am a software engineer. I enjoy working with full-stack Javascript applications and 
              am comfortable with the vast majority of the major backend and frontend
              frameworks however I am always learning more. For mobile development, I primarily
              stick to Flutter but I am starting to enjoy certain aspects of Swift so maybe that will change.
            </p>
            <p>
              I currently hold a 3.89 GPA on my path to complete my computer science degree. 
              I was a Lambda School student and thrived in my track which allowed me to tutor my peers through their paths.
            </p>
            <p>
              I am passionate about technology with a focus on
              web development. I specialize in the MERN, MEVN, and MEAN stacks,
              however, I am not latched to a specific to a framework or
              language.
            </p>
            <p>
              In this line of work, it's about adaptation, and what works best
              for the project. I am open and look forward to learning any stack
              or language.
            </p>
          </div>
        </div>
      </main>
      <div id="about__pyramid">
        <div className="pyramid__row">
          <img
            className="about__image"
            src={javaScriptLogo}
            onClick={() => handleClick("#FCDA4B", "#FFFFFF")}
            alt="logo for experiences that have happened"
          />
        </div>
        <div className="pyramid__row">
          <img
            className="about__image"
            src={htmlLogo}
            onClick={() => handleClick("#EF6537", "#FFFFFF")}
            alt="logo for experiences that have happened"
          />
          <img
            className="about__image"
            src={css3Logo}
            onClick={() => handleClick("#71B2DE", "#FFFFFF")}
            alt="logo for experiences that have happened"
          />
        </div>
        <div className="pyramid__row">
          <img
            className="about__image"
            src={vueLogo}
            onClick={() => handleClick("#53B883", "#35495E")}
            alt="logo for experiences that have happened"
          />
          <img
            className="about__image"
            src={reactLogo}
            onClick={() => handleClick("#5AD8F9", "#FFFFFF")}
            alt="logo for experiences that have happened"
          />
          <img
            className="about__image"
            src={angularLogo}
            onClick={() => handleClick("#DD3432", "#FFFFFF")}
            alt="logo for experiences that have happened"
          />
        </div>
        <div className="pyramid__row">
          <img
            className="about__image"
            src={nodeLogo}
            onClick={() => handleClick("#90C53F", "#35495E")}
            alt="logo for experiences that have happened"
          />
          <img
            className="about__image"
            src={javaLogo}
            onClick={() => handleClick("#E77036", "#5382A1")}
            alt="logo for experiences that have happened"
          />
          <img
            className="about__image"
            src={pythonLogo}
            onClick={() => handleClick("#FBD349", "#225866")}
            alt="logo for experiences that have happened"
          />
          <img
            className="about__image"
            src={lambdaLogo}
            onClick={() => handleClick("#B92B34", "#fff")}
            alt="logo for experiences that have happened"
          />
        </div>
      </div>
    </div>
  );
}

export default About;

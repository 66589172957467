import React from "react";
import "./skillCardsAll.scss";

function SkillCard(props) {
  return (
    <div id="skillCard">
      <div id="skillCard__title-container">
        <h2>{props.skillArea && props.skillArea}</h2>
      </div>
      <div id="skillCard__content">
        {props.content &&
          props.content.map((bar, key) => (
            <div key={key} id="skillCard__items">
              <div>
                <img src={bar.image} alt="skill logo" />
              </div>
              <h4>{bar.techName && bar.techName}</h4>
            </div>
          ))}
      </div>
    </div>
  );
}

export default SkillCard;

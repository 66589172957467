import React from "react";
import "./skills.scss";
import CardContainer from "../../components/skillCards/CardContainer";

function Skills() {
  return (
    <div id="skills">
      <main>
        <div id="skills__text-container">
          <h1>
            What do <br /> I specialize in?
          </h1>
          <div id="skills__article-wrapper">
            <p>
              My <b>web development</b> strengths are with MERN, MEVN,
              or MEAN. However, I also have experience using a Java Spring
              backend with a Thymeleaf frontend as well as Python with Django.
            </p>
            <p>
              My <b>mobile development</b> strengths are Flutter
              applications with a BLoC or Redux state management. Currently I am
              also working into Swift development for a more custom experience.
            </p>
            <p>
              As mentioned in the about page, I fully acknowledge that in this
              line of work, it's about adaptation, and what works best for the
              project. I am open and look forward to learning any stack or
              language.
            </p>
          </div>
        </div>
      </main>
      <CardContainer />
    </div>
  );
}

export default Skills;

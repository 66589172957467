import React from "react";
import "./projectCard.scss";

function ProjectCard({ card }) {
  return (
    <div id="projectCard">
      <div id="projectCard__tag">
        {card.toolLogos.map((logo, index) => (
          <img src={logo} key={index} alt="tec used for project" />
        ))}
      </div>
      <div id="projectCard__inner">
        <div id="projectCard__image-wrapper">
          <img src={card.image} alt="project example by matthew endicott" />
        </div>
        <div id="projectCard__content">
          <div id="content__text">
            <i className="fas fa-chevron-up"></i>
            <div id="text__inner">
              <h2>{card.title}</h2>
              <p>{card.info}</p>
            </div>
          </div>
          <div id="content__buttons-container">
            <a rel="noopener noreferrer" target="_blank" href={card.pageUrl}>
              Demo
            </a>
            {card.gitURL !== "none" ? (
              <a rel="noopener noreferrer" target="_blank" href={card.gitURL}>
                GitHub
              </a>
            ) : (
              <p>
                Current Project
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectCard;
